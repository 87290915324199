@import '../../styles/colors.scss';

header {
  position: sticky;
  top: 0;
  background-color: $color--white;
  z-index: 100;
}
.container {
  padding: 32px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: $font-family-title;
  font-weight: 600;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 1200px) {
    padding: 32px 20px;
  }
}
.navigation {
  display: flex;
  align-items: center;
  gap: 24px;
  height: 42px;
}
.item {
  text-decoration: none;
  color: $color--lightgray;

  &__active {
    color: $color--blue;
  }
}

.burger_container {
  & .active {
    & > span {
      width: 0;
      height: 0;
    }
  }
}

.burger_button,
.burger_button_active {
  width: 30px;
  height: 20px;
  position: relative;
  cursor: pointer;

  & span {
    position: absolute;
    top: 9px;
    width: 30px;
    height: 2px;
    background-color: $color--blue;
  }
}

.burger_button {
  &::before {
    content: '';
    position: absolute;
    top: 0;
    width: 30px;
    height: 2px;
    background-color: $color--blue;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 30px;
    height: 2px;
    background-color: $color--blue;
  }
}

.burger_button_active {
  & span {
    transform: rotate(45deg);
  }

  &::before {
    content: '';
    position: absolute;
    top: 9px;
    width: 30px;
    height: 2px;
    background-color: $color--blue;
    transform: rotate(-45deg);
  }
}

.logo {
  cursor: pointer;
}
