$color--blue: #1d14bf;
$color--white: #fff;
$color--light: #FAFBFF;

$color--lightblue: #90a7ff;
$color--extra-lightblue: #eaf0ff;
$color--lightgray: #d1d1d1;

$color--container-blue: #d8e3ff;

$color--text-blue: #3F63EA;
$color--text-light: #4f4f4f;
$color--text-dark: #1f1f1f;

$font-family-main: "Roboto";
$font-family-title: "Chakra Petch";
