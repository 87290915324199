@import '../../styles/colors.scss';

.container {
  padding: 100px 0;
  margin-top: -50px;
  border-radius: 20px 20px 0 0;
  @media (max-width: 1200px) {
    padding: 100px 20px;
  }
}
.container_inner {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto 50px;
}
.title {
  width: 60%;
  color: $color--text-dark;
  font-family: $font-family-title;
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;

  @media (max-width: 768px) {
    width: 100%;
    font-size: 36px;
    line-height: 36px;
  }
}

.secondaryText {
  width: 60%;
  @media (max-width: 768px) {
    width: 100%;
  }
}
