@import '../../styles/colors.scss';

.section {
  overflow: hidden;
}

.wrapper {
  padding: 0 0 100px;
  display: flex;
  justify-content: center;
  background-color: $color--extra-lightblue;
  border-radius: 20px 20px 0 0;
  @media (max-width: 1200px) {
    padding: 0 20px 100px;
  }
}

.contentContainer {
  width: 75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 1200px) {
    width: 100%;
  }
}

.sectionHeader {
  align-self: flex-start;
  padding-top: 100px;
  font-family: Chakra Petch;
  font-size: 3.75rem;
  line-height: 3.625rem;
  font-weight: 700;
  text-transform: uppercase;
  color: $color--text-dark;

  @media (max-width: 768px) {
    font-size: 36px;
    line-height: 36px;
  }
}

.geographyData {
  padding-top: 100px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 2rem;

  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.geographyDataElement {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  @media (max-width: 1200px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }

  & .elementTitle {
    width: 10rem;
    color: $color--text-light;
    font-size: 1.5rem;
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  & .elementTags {
    color: $color--text-dark;
    width: 25rem;
    word-spacing: 1rem;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
}

.mapImage {
  padding-top: 80px;

  & > img {
    width: 100%;
    height: 100%;
  }
}
