@import '../../styles/colors.scss';

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  padding: 16px 32px;
  gap: 8px;
  color: $color--white;
  border: none;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.2s;

  &[data-full-width='true'] {
    width: 100%;
  }

  &__solid {
    background-color: $color--blue;
  }
  &__bordered {
    color: $color--blue;
    border: 2px solid $color--blue;
    background-color: transparent;
    font-weight: 700;
    &:hover {
      background-color: $color--blue;
      color: $color--white;
    }
  }
  &__ghost {
    background-color: $color--lightblue;
    &:hover {
      background-color: $color--blue;
    }
  }
}
