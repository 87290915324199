.fadeInSection {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition:
    opacity 200ms ease-out,
    transform 600ms ease-out,
    visibility 600ms ease-out;
  will-change: opacity, transform, visibility;
}
.fadeInSection.isVisible {
  opacity: 1;
  transform: none;
  visibility: visible;
}
